/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
import includes from 'lodash/includes'
import values from 'lodash/values'
import moment from 'moment'
import momenttz from 'moment-timezone'
import { getFormValues } from 'redux-form'
import getAccount from '../../selectors/getAccount'
import getPayee from '../../selectors/getPayee'
import * as bH from '../../utils/bankHolidays'
import { decodeHTML } from '../../utils/decodeHTML'
import { saveeReceiptPDF } from '../../utils/savePDF'

export const checkPaymentDateWarning = (state, formData, details) => {
  const now = momenttz().tz('Europe/London').day()
  const formattedNow = momenttz().tz('Europe/London').format('YYYY-MM-DD')
  let laterDate = ''
  let formattedLaterDate
  let warning = ''
  if (details.laterDate) {
    laterDate = momenttz(details.laterDate, 'DD/MM/YY').day()
    formattedLaterDate = momenttz(details.laterDate, 'DD/MM/YY').format('YYYY-MM-DD')
  }
  if (!details.isRegular && formData === 'now') {
    if (now === 0 || now === 6 || includes(bH.bankHolidays, formattedNow)) {
      warning = `Your payment will be sent immediately, but the date shown on
        your statement and online will be the next working day.`
    }
  } else if (formData === 'later') {
    let count = 0
    if (laterDate === 0 || laterDate === 6 || includes(bH.bankHolidays, formattedLaterDate)) {
      while (laterDate === 0 || laterDate === 6 || includes(bH.bankHolidays, formattedLaterDate)) {
        warning = ''
        laterDate++
        if (laterDate === 7) {
          laterDate = 0
        }
        count++
        formattedLaterDate = momenttz(details.laterDate, 'DD/MM/YY')
          .add(count, 'd')
          .format('YYYY-MM-DD')
      }
      const newDate = momenttz(details.laterDate, 'DD/MM/YY').add(count, 'd').format('DD-MM-YYYY')
      warning = `Your payment will be processed on ${newDate}`
    }
  }
  return warning
}

export const displayAccountList = (state, list, initialNumberShown) => {
  const newList = values(list)

  if (state.ui.panel === 'showAllAccounts') {
    return newList
  }
  return newList.slice(0, initialNumberShown)
}

export const getShowAllIsVisible = (state, list, initialNumberShown?: any) => {
  const listLength = Object.keys(list).length

  if (state.ui.panel === 'showAllAccounts' || listLength <= initialNumberShown) {
    return false
  }
  return true
}

export const getStage = (state) => {
  const pm = state.paymentMake
  if (pm.from === undefined) return 0
  if (pm.to === undefined) {
    if (pm.isNewPayee) return 1.1
    return 1
  }
  if (pm.amount === undefined) return 2
  if (pm.success === undefined) return 3
  return 4
}

export const getTitle = (state) => {
  const stage = getStage(state)

  if (stage < 3) return 'Make a Payment'
  if (stage === 3) return 'Confirm payment'
  if (stage === 4) return 'Payment complete'
}
export const hasFunds = (state, accountId, amount) => {
  const balance = accountId.availableBalancePure
  return balance - amount.toFixed(2) >= 0
}
export const hasTransactionRange = (state, customerId, amount) => {
  let minUserPaymentLimitRange
  minUserPaymentLimitRange =
    state.user.userPaymentLimit && state.user.userPaymentLimit.txnRange.minTransaction
  let maxUserPaymentLimitRange
  maxUserPaymentLimitRange =
    state.user.userPaymentLimit && state.user.userPaymentLimit.txnRange.maxTransaction
  let minPartyPaymentLimitRange
  minPartyPaymentLimitRange =
    state.user.partyPaymentLimit && state.user.partyPaymentLimit[customerId].txnRange.minTransaction
  let maxPartyPaymentLimitRange
  maxPartyPaymentLimitRange =
    state.user.partyPaymentLimit && state.user.partyPaymentLimit[customerId].txnRange.maxTransaction
  let minTransactionRange = minUserPaymentLimitRange
  let maxTransactionRange = maxUserPaymentLimitRange
  if (minUserPaymentLimitRange < minPartyPaymentLimitRange) {
    minTransactionRange = minPartyPaymentLimitRange
  }
  if (maxUserPaymentLimitRange > maxPartyPaymentLimitRange) {
    maxTransactionRange = maxPartyPaymentLimitRange
  }
  if (parseFloat(minTransactionRange) > amount.toFixed(2)) {
    return 'amountTooSmall'
  }
  if (parseFloat(maxTransactionRange) < amount.toFixed(2) || amount.toFixed(2) > 100000) {
    return 'amountTooBig'
  }
  return ''
}
export const hasDailyLimitLeft = (state, customerId, amount) => {
  let userPaymentLimit
  userPaymentLimit = state.user.userPaymentLimit ? state.user.userPaymentLimit.maxAmount : 100000
  let partyPaymentLimit
  partyPaymentLimit = state.user.partyPaymentLimit
    ? state.user.partyPaymentLimit[customerId].maxAmount
    : 100000
  let transactionDailyLimit = partyPaymentLimit
  if (userPaymentLimit < partyPaymentLimit) {
    transactionDailyLimit = userPaymentLimit
  }
  return transactionDailyLimit - amount.toFixed(2) >= 0
}
export const hasDailyTransactionCountLeft = (state, customerId) => {
  let userPaymentCountsLeft
  userPaymentCountsLeft = state.user.userPaymentLimit ? state.user.userPaymentLimit.maxCount : 999
  let partyPaymentCountsLeft
  partyPaymentCountsLeft = state.user.partyPaymentLimit
    ? state.user.partyPaymentLimit[customerId].maxCount
    : 999
  let transactionCountLeft = partyPaymentCountsLeft
  if (userPaymentCountsLeft < partyPaymentCountsLeft) {
    transactionCountLeft = userPaymentCountsLeft
  }
  return transactionCountLeft > 0
}

export const getPaymentMakeDetails = (state) => state.paymentMake

export const getFromId = (state) => state.paymentMake.from
export const getToId = (state) => state.paymentMake.to

export const getFromAccount = (state) => getAccount(state, getFromId(state))
export const getTheNewPayee = (state) => state.form.paymentMakeVerify.values
export const getExistingPayees = (state) => state.payees.list.items
export const getSortCode = (state) =>
  state.paymentMake.newPayeeDetails
    ? state.paymentMake.newPayeeDetails.sortCode
    : getExistingPayees(state)[getToId(state)].sortCode
export const getToAccount = (state) => {
  if (!state.paymentMake.to) {
    return undefined
  }
  if (state.paymentMake.isNewPayee) {
    return state.paymentMake.newPayeeDetails
  }
  return getPayee(state, getToId(state))
}

export const getBankDetails = (state) => state.paymentMake.newPayeeDetails

export const saveeReceipt = (state) => {
  const toAccount = getToAccount(state)
  const { accountNumber: toAccountNumber, sortCodeNice, title } = toAccount
  const fromAccount = getFromAccount(state)
  const { accountNumber: fromAccountNumber, currency } = fromAccount
  const details = getPaymentMakeDetails(state)
  const { repeatFreq, amount, when, reference, repeatsUntil } = details
  let frequency = ''
  let until = ''

  const pdfConfig = {
    title: 'Payment',
    rows: [
      { label: 'From account', value: decodeHTML(fromAccount.title) },
      { label: '', value: `60-93-03 XXXXX${String(fromAccountNumber).substring(5)}` },
      { label: '', value: '' },
      { label: 'Payee account', value: decodeHTML(title) },
      { label: '', value: `${sortCodeNice} XXXXX${String(toAccountNumber).substring(5)}` },
      { label: '', value: '' },
      { label: 'Amount', value: `${amount} ${currency}` },
      { label: `${repeatsUntil ? 'Start date' : 'Date'}`, value: when },
      { label: 'Reference', value: reference || '' },
    ],
  }

  if (repeatFreq) {
    frequency = {
      label: 'Frequency',
      value: repeatFreq,
    }
    until = {
      label: 'Until',
      value: repeatsUntil,
    }
    if (repeatsUntil === undefined) {
      until = {
        label: 'Until',
        value: 'Until further notice',
      }
    }
    pdfConfig.rows.splice(8, 0, until)
    pdfConfig.rows.splice(8, 0, frequency)
  }

  saveeReceiptPDF(pdfConfig)
}

export const showRecurringWarning = (state) => {
  const { when, repeatFreq } = state.paymentMake
  const now = moment().format('DD/MM/YY')
  return repeatFreq && when === now
}

export const getHasReference = (state) => {
  const formVals = getFormValues('paymentMakeVerify')(state) || {}
  return formVals && formVals.reference && formVals.reference.length > 0
}
