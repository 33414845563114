/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
const alphabet: string = 'abcdefghijklmnopqrstuvwxyz'
const onlyLength =
  (requiredLength: number) => (value: { [key: string]: string } | string | boolean | number) =>
    value === undefined || value.length !== requiredLength
      ? `onlyLength${requiredLength}`
      : undefined
/* eslint-disable no-unused-vars */
const maxLength = (max: number) => (value: string) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
/* eslint-enable no-unused-vars */
const maxLengthAN = (max: number) => (value: string) =>
  value && value.length > max ? `Must be ${max} alphanumeric characters or less` : undefined
const maxPayeeNameAN = (max: number) => (value: string) =>
  value && value.length > max ? 'maxPayeeNameAN' : undefined
export const maxRepetitions = (max: number) => (value: string) => {
  const regex = new RegExp(`(.)\\1{${max},}`)
  return regex.test(value) ? `maxRepetitions${max}` : undefined
}
export const maxSuccessive = (max: number) => (value: string) => {
  if (!value) return undefined
  let posReps: number
  let negReps: number
  posReps = 0
  negReps = 0
  // Check digits
  const err: string = `maxSuccessive${max}`
  for (let i: number = 0; i < value.length - 1; i += 1) {
    const current: number = parseInt(value[i])
    const next: number = parseInt(value[i + 1])
    current + 1 === next ? posReps++ : (posReps = 0)
    current - 1 === next ? negReps++ : (negReps = 0)
    if (posReps > max - 1 || negReps > max - 1) return err
  }
  posReps = 0
  negReps = 0
  // Check letters
  for (let i: number = 0; i < value.length - 1; i++) {
    const current: number = alphabet.indexOf(value[i].toLowerCase())
    const next: number = alphabet.indexOf(value[i + 1].toLowerCase())
    if (current !== -1 && next !== -1) {
      current + 1 === next ? posReps++ : (posReps = 0)
      current - 1 === next ? negReps++ : (negReps = 0)
    }
    if (posReps > max - 1 || negReps > max - 1) return err
  }
  return undefined
}
export const matchesField =
  (matchWith: string) =>
  (
    value: string | number,
    allValues: {
      [key: string]: string | number
    }
  ) =>
    value === undefined || value !== allValues[matchWith] ? 'matchesField' : undefined
export const required = (value: boolean | string | number) => (!value ? 'required' : undefined)
export const enoughFunds = (value: number, reference: number) =>
  value < reference ? 'Not enough Funds' : undefined
export const atLeastLength8 = (value: string) =>
  value === undefined || value.length < 8 ? 'atLeastLength8' : undefined
export const between8And20Chars = (value) =>
  value === undefined || value.length < 8 || value.length > 20 ? 'between8And20Chars' : undefined
export const atLeastOneLowerCase = (value) =>
  value === undefined || value.toUpperCase() === value ? 'atLeastOneLowerCase' : undefined
export const atLeastOneUpperCase = (value: string) =>
  value === undefined || value.toLowerCase() === value ? 'atLeastOneUpperCase' : undefined
export const atLeastOneUpperCaseAndLowerCase = (value: string) => {
  if (atLeastOneLowerCase(value) === undefined && atLeastOneUpperCase(value) === undefined) {
    return undefined
  }
  return 'atLeastOneUpperCaseAndLowerCase'
}
export const onlyPermittedChars = (value: string) => {
  const regex = new RegExp('^[a-zA-Z0-9_#@$]*$')
  return regex.test(value) ? undefined : 'onlyPermittedChars'
}

export const onlyPermittedCharPayeeName = (value: string) => {
  const regex = new RegExp("^[a-zA-Z0-9][a-z A-Z 0-9 ._+&$!*|()!#?:`,/'-]*$")
  return regex.test(value) ? undefined : 'onlyPermittedCharPayeeName'
}
export const reference = (value: string) =>
  value && /[^a-zA-Z0-9 ]/i.test(value) ? 'Only alphanumeric characters permitted' : undefined
export const payeeDescription = (value: string) =>
  value && /[^a-zA-Z0-9 ]/i.test(value) ? 'Only alphanumeric characters permitted' : undefined
export const strongPassword = (value: string) => {
  const errors: string[] = [
    maxSuccessive(3)(value),
    maxRepetitions(5)(value),
    onlyPermittedChars(value),
    atLeastOneUpperCaseAndLowerCase(value),
    between8And20Chars(value),
  ]
  return errors.every((item) => item === undefined) ? undefined : errors
}
export const strongTransactionPin = (value: string) => {
  const errors = [maxSuccessive(3)(value), maxRepetitions(2)(value), onlyLength4(value)]
  return errors.every((item) => item === undefined) ? undefined : errors
}
export const matchesFieldInList = (matchWith: string) => {
  const errors = [matchesField(matchWith)]
  return errors.every((item) => item === undefined) ? undefined : errors
}
export const onlyLength8 = onlyLength(8)
export const onlyLength6 = onlyLength(6)
export const onlyLength4 = onlyLength(4)
export const onlyLength2 = onlyLength(2)
export const maxLength35AN = maxLengthAN(35)
export const maxPayeeName35AN = maxPayeeNameAN(35)
export const accountNo = [onlyLength8, required]
export const sortCode = [onlyLength6, required]
export const sortCodeField = [onlyLength2, required]
export const transactionPin = [onlyLength4, required]
export const payeeName = [maxPayeeName35AN, onlyPermittedCharPayeeName, required]
