import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import PayeeFields from '../../components/Forms/PayeeFields'
import { sortCodeValidate } from '../../sagas/sortCodeValidate/actions'
import { getBankName } from './selectors'

const mapStateToProps = (state, ownProps) => ({
  bankName: getBankName(state, ownProps),
  accountType: formValueSelector(ownProps.formName)(state, 'accountType'),
  payeeDescriptionChecked: formValueSelector(ownProps.formName)(state, 'payeeDescriptionChecked'),
  error: ownProps.error || null,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSortCodeChange: () => {
    dispatch(sortCodeValidate(ownProps.formName))
  },
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PayeeFields)
