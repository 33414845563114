export const maxLength = (max: number) => (value: string) => value.slice(0, max)

export const onlyNumbers = (value: string) => value.replace(/[^0-9]/g, '')

export const alwaysUppercase = (value: string) => value.toUpperCase()
export const trimAndUpperCase = (value: string) => value.trim().toUpperCase()

export const onlyDecimal = (inputValue: string) => {
  const value = inputValue.replace(/[^0-9.]/g, '') // Remove all chars except numbers and .

  // Create an array with sections split by .
  const sections: string[] = value.split('.')

  // Remove any leading 0s apart from single 0
  if (sections[0] !== '0' && sections[0] !== '00') {
    sections[0] = sections[0].replace(/^0+/, '')
  } else {
    sections[0] = '0'
  }

  // If numbers exist after first .
  if (sections[1]) {
    // Join first two sections and truncate end section to length 2
    return `${sections[0]}.${sections[1].slice(0, 2)}`
    // If original value had a decimal place at the end, add it back
  }
  if (value.indexOf('.') !== -1) {
    return `${sections[0]}.`
    // Otherwise, return only section
  }
  return sections[0]
}

export const maxLength8 = maxLength(8)
export const maxLength2 = maxLength(2)
export const maxLength4 = maxLength(4)

export const transactionPin = (value) => maxLength4(onlyNumbers(value))

export const accountNo = (value) => maxLength8(onlyNumbers(value))

export const sortCodeField = (value) => maxLength2(onlyNumbers(value))
