/**
 * Transforms state items object into options array
 * @param  {Object} items
 * @return {Array} newItems
 */

export default (items) => {
  // TODO
  if (items) {
    const newItems: { label: string; value: string | number }[] = []

    Object.keys(items).forEach((key) => {
      const item = items[key]

      newItems.push({
        label: item.title,
        value: item.id,
      })
    })

    return newItems
  }
  return []
}
