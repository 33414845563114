import moment from 'moment'
import * as c from '../../constants'

export interface ISuperDatePickerState {
  pickers: any // todo
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState: ISuperDatePickerState = {
  pickers: {},
}

export const updateRange = (picker, date, wasSelecting) => {
  const oldStartDate = picker.startDate
  const oldEndDate = picker.endDate

  let startDate
  let endDate

  if (wasSelecting === 'start') {
    startDate = date
    endDate = oldEndDate
  } else {
    startDate = oldStartDate
    endDate = date
  }

  if (startDate.isAfter(endDate)) {
    startDate = date
    endDate = date
  }

  const startDateText = moment(startDate).format('DD/MM/YY')
  const endDateText = moment(endDate).format('DD/MM/YY')

  return {
    startDate,
    endDate,
    startDateText,
    endDateText,
  }
}
export const updateStartDate = (date, wasSelecting) => {
  let startDate
  if (wasSelecting === 'start' || wasSelecting === 'end') {
    startDate = date
  }

  const startDateText = moment(startDate).format('DD/MM/YY')
  return {
    startDate,
    startDateText,
  }
}

export const updateEndState = (date, wasSelecting) => {
  let endDate

  if (wasSelecting === 'start' || wasSelecting === 'end') {
    endDate = date
  }

  const endDateText = moment(endDate).format('DD/MM/YY')
  return {
    endDate,
    endDateText,
  }
}
const defaultStartDate = moment().startOf('day').subtract(7, 'days')
const defaultEndDate = moment().startOf('day')

const UpdateFunctionMap = {
  [c.SUPERDATEPICKER_CHANGE]: updateRange,
  [c.SUPERSTARTDATEPICKER_CHANGE]: updateStartDate,
  [c.SUPERENDDATEPICKER_CHANGE]: updateEndState,
}

export default function superDatePickerReducer(
  state = initialState,
  action
): ISuperDatePickerState {
  const p = action.payload

  switch (action.type) {
    case c.SUPERDATEPICKER_INIT: {
      const startDate = p.startDate ? p.startDate : defaultStartDate
      const endDate = p.endDate ? p.endDate : defaultEndDate
      return {
        ...state,
        pickers: {
          ...state.pickers,
          [p.datePickerId]: {
            selecting: 'start',
            maxDate: moment().startOf('day'),
            startDate,
            endDate,
            lastSubmittedStartDate: startDate,
            lastSubmittedEndDate: endDate,
            startDateText: moment(startDate).format('DD/MM/YY'),
            endDateText: moment(endDate).format('DD/MM/YY'),
          },
        },
      }
    }
    case c.SUPERDATEPICKER_CANCEL: {
      const { lastSubmittedStartDate } = state.pickers[p.datePickerId]
      const { lastSubmittedEndDate } = state.pickers[p.datePickerId]
      const startDate = p.startDate || lastSubmittedStartDate || defaultStartDate
      const endDate = p.endDate || lastSubmittedEndDate || defaultEndDate

      return {
        ...state,
        pickers: {
          ...state.pickers,
          [p.datePickerId]: {
            ...state.pickers[p.datePickerId],
            selecting: 'start',
            startDate,
            endDate,
            startDateText: moment(startDate).format('DD/MM/YY'),
            endDateText: moment(endDate).format('DD/MM/YY'),
          },
        },
      }
    }
    case c.SUPERDATEPICKER_CHANGE:
    case c.SUPERSTARTDATEPICKER_CHANGE:
    case c.SUPERENDDATEPICKER_CHANGE: {
      const picker = state.pickers[p.datePickerId]
      const selecting = picker.selecting === 'start' ? 'end' : 'start'
      const updateFunctionMap = UpdateFunctionMap[action.type]

      const dateUpdate =
        action.type === c.SUPERDATEPICKER_CHANGE
          ? updateFunctionMap(picker, p.date, picker.selecting)
          : updateFunctionMap(p.date, picker.selecting)

      return {
        ...state,
        pickers: {
          ...state.pickers,
          [p.datePickerId]: {
            ...picker,
            ...dateUpdate,
            selecting,
          },
        },
      }
    }

    case c.SUPERDATEPICKER_INPUT_CLICK: {
      return {
        ...state,
        pickers: {
          ...state.pickers,
          [p.datePickerId]: {
            ...state.pickers[p.datePickerId],
            selecting: p.inputId,
          },
        },
      }
    }
    case c.SUPERDATEPICKER_INPUT_CHANGE: {
      return {
        ...state,
        pickers: {
          ...state.pickers,
          [p.datePickerId]: {
            ...state.pickers[p.datePickerId],
            [`${p.inputId}DateText`]: p.value,
          },
        },
      }
    }
    case c.SUPERDATEPICKER_NATIVE_INPUT_CHANGE: {
      const date = moment(p.date, 'YYYY-MM-DD')
      return {
        ...state,
        pickers: {
          ...state.pickers,
          [p.datePickerId]: {
            ...state.pickers[p.datePickerId],
            ...updateRange(state.pickers[p.datePickerId], date, p.inputId),
          },
        },
      }
    }
    case c.SUPERDATEPICKER_INPUT_BLUR: {
      const dateText = state.pickers[p.datePickerId][`${p.inputId}DateText`]
      const prop = `${p.inputId}Date`
      const m = moment(dateText, 'DD/MM/YY')
      const date = m.isValid() ? m : state.pickers[p.datePickerId][prop]

      return {
        ...state,
        pickers: {
          ...state.pickers,
          [p.datePickerId]: {
            ...state.pickers[p.datePickerId],
            ...updateRange(state.pickers[p.datePickerId], date, p.inputId),
          },
        },
      }
    }
    case c.SUPERDATEPICKER_SHORTCUT_CLICK: {
      let startDate
      let endDate

      switch (p.shortcutId) {
        case 'yesterday':
          startDate = moment().subtract(1, 'days').startOf('day')
          endDate = moment().subtract(1, 'days').startOf('day')
          break
        case 'last7days':
          startDate = moment().subtract(7, 'days').startOf('day')
          endDate = moment().startOf('day')
          break
        case 'prevMonth':
          startDate = moment(state.pickers[p.datePickerId].endDate)
            .subtract(1, 'month')
            .startOf('month')
          endDate = moment(state.pickers[p.datePickerId].endDate)
            .subtract(1, 'months')
            .endOf('month')
          break
        case 'nextMonth':
          startDate = moment(state.pickers[p.datePickerId].endDate).add(1, 'month').startOf('month')
          endDate = moment(state.pickers[p.datePickerId].endDate).add(1, 'months').endOf('month')
          break
        default:
          startDate = state.pickers[p.datePickerId].startDate
          endDate = state.pickers[p.datePickerId].endDate
      }

      return {
        ...state,
        pickers: {
          ...state.pickers,
          [p.datePickerId]: {
            ...state.pickers[p.datePickerId],
            startDate,
            endDate,
            startDateText: startDate.format('DD/MM/YY'),
            endDateText: endDate.format('DD/MM/YY'),
          },
        },
      }
    }
    case c.SUPERDATEPICKER_UPDATE_LAST_SUBMITTED: {
      return {
        ...state,
        pickers: {
          ...state.pickers,
          [p.datePickerId]: {
            ...state.pickers[p.datePickerId],
            lastSubmittedStartDate: state.pickers[p.datePickerId].startDate,
            lastSubmittedEndDate: state.pickers[p.datePickerId].endDate,
          },
        },
      }
    }
    default: {
      return state
    }
  }
}
