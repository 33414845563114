import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import moment, { Moment } from 'moment'
import s from './styles.css'
import SubmitCancel from '../../Forms/SubmitCancel'
import Shortcuts from '../Shortcuts'

interface LargeProps {
  startDateText: string
  endDateText: string
  onChange: () => void
  onHandleStartDateChange: () => void
  onHandleEndDateChange: () => void
  onInputClick: (v: string) => void
  onInputBlur: (v: string) => void
  onInputChange: (v1: string, v2: string) => void
  onInputKeypress: (v1: string, v2: any) => void
  onShortcutClick: () => void
  selecting?: 'start' | 'end'
  startDate: moment.Moment
  endDate: moment.Moment
  maxDate: moment.Moment
  onSubmit: () => void
  onCancelClick: () => void
  shortcuts?: any[]
}
interface LargeState {
  isStartDatePickerOpen: boolean
}
class Large extends Component<LargeProps, LargeState> {
  constructor(props) {
    super(props)
    this.state = {
      isStartDatePickerOpen: false,
    }

    this.onStartFocus = this.onStartFocus.bind(this)
    this.onStartBlur = this.onStartBlur.bind(this)
    this.onStartChange = this.onStartChange.bind(this)
    this.onStartKeyPress = this.onStartKeyPress.bind(this)
  }

  onStartFocus() {
    this.props.onInputClick('start')
    this.setState({ isStartDatePickerOpen: true })
  }

  onStartBlur() {
    this.props.onInputBlur('start')
  }

  onStartChange(e) {
    this.props.onInputChange('start', e.target.value)
  }

  onStartKeyPress(e) {
    this.props.onInputKeypress('start', e)
    if (e.key === 'Enter') {
      this.setState({ isStartDatePickerOpen: true })
    }
  }

  onEndFocus() {
    this.props.onInputClick('end')
  }

  onEndBlur() {
    this.props.onInputBlur('end')
  }

  onEndChange(e) {
    this.props.onInputChange('end', e.target.value)
  }

  onEndKeyPress(e) {
    this.props.onInputKeypress('end', e)
  }

  render() {
    const { isStartDatePickerOpen } = this.state
    const {
      onChange,
      onHandleStartDateChange,
      onHandleEndDateChange,
      onSubmit,
      selecting,
      startDate,
      endDate,
      startDateText,
      endDateText,
      maxDate,
      shortcuts,
      onShortcutClick,
      onCancelClick,
    } = this.props

    let startClass
    let endClass
    let selectsStart
    let selectsEnd

    if (selecting === 'start') {
      startClass = s.inputActive
      selectsStart = true
    } else {
      endClass = s.inputActive
      selectsEnd = true
    }

    return (
      <form className={s.root} onSubmit={onSubmit}>
        <div className={s.calendars}>
          <DatePicker
            inline
            monthsShown={2}
            selectsStart={selectsStart}
            selectsEnd={selectsEnd}
            startDate={startDate}
            endDate={endDate}
            openToDate={startDate}
            maxDate={maxDate}
            onChange={onChange}
          />
        </div>
        <div className={s.controls}>
          <div className={s.inputs}>
            <div className={s.input}>
              <label htmlFor='dateStart'>Start date</label>
              <div className={s.calendars}>
                <DatePicker
                  selectsStart
                  openToDate={startDate}
                  maxDate={maxDate}
                  value={startDateText}
                  onChange={onHandleStartDateChange}
                  placeholderText='Select an start date'
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode='select'
                />
              </div>
            </div>
            <div className={s.input}>
              <label htmlFor='dateEnd'>End Date</label>
              <div className={s.calendars}>
                <DatePicker
                  selectsEnd
                  openToDate={maxDate}
                  minDate={startDate}
                  value={endDateText}
                  onChange={onHandleEndDateChange}
                  placeholderText='Select an end date'
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode='select'
                />
              </div>
            </div>
          </div>

          <Shortcuts items={shortcuts} onShortcutClick={onShortcutClick} />

          <div className={s.actions}>
            <SubmitCancel submitText='Apply Dates' onCancelClick={onCancelClick} />
          </div>
        </div>
      </form>
    )
  }
}

export default Large
