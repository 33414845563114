import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import { getFormValues, reset, stopSubmit } from 'redux-form'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import APIError from '../../utils/APIError'
import dateOptionsProcess from '../../utils/dateOptionsProcess'
import formatSortCode from '../../utils/formatSortCode'
// import GA from '../../utils/GA'
import {
  PAYMENT_FLOW_CONFIRMATION_OF_PAYEE_SUBMIT,
  PAYMENTMAKE_INIT,
  requestGroupTransactionLimit,
  requestGroupTransactionLimitSpent,
  requestNonce,
  requestPayeeNew,
  requestPayeeNewInit,
  requestPayeeNewInit2,
  requestPayeeNewInit3,
  requestTransferOrPayment,
  requestTransferOrPaymentGet,
  requestTransferOrPaymentPatch,
  requestUserTransactionLimit,
  requestUserTransactionLimitSpent,
  TWO_FACTOR_AUTH_COMPLETE,
  U_PAYMENTMAKE_FROM_UPDATE,
} from '../../constants'
import { DIGXErrorCodes } from '../../models/enum'
import { formError } from '../../sagas/formErrors/actions'
import getAccount from '../../selectors/getAccount'
import { modalErrorShow, modalHide } from '../../store/ui'
import { userDetailsUpdate } from '../../store/user'
import humanError from '../../utils/humanError'
import request from '../../utils/request'
import topOfPage from '../../utils/topOfPage'
import { accountsFetch } from '../Accounts/actions'
import { confirmPayee, copReset } from '../ConfirmationOfPayee/actions'
import { getCopApiResponse } from '../ConfirmationOfPayee/selector'
import { payeesFetchRequest } from '../Payees/actions'
import {
  PINSubmit,
  twoFactorAuthSetInitialRequest,
  twoFactorAuthSetRef,
  twoFactorAuthSetSuccessAction,
} from '../TwoFactorAuth/actions'
import {
  paymentMakeDetailsUpdate,
  paymentMakeNewPayeeDetailsClear,
  paymentMakeNewPayeeDetailsUpdate,
  paymentMakeNewPayeeShow,
  paymentMakeReset,
  paymentMakeResetSuccess,
  paymentMakeSubmit,
  paymentMakeToUpdate,
  paymentMakeVerify,
  rPaymentMakeFromUpdate,
  uPaymentMakeFromUpdate,
} from './actions'
import {
  checkPaymentDateWarning,
  getExistingPayees,
  getFromAccount,
  getPaymentMakeDetails,
  getSortCode,
  getTheNewPayee,
  hasDailyLimitLeft,
  hasDailyTransactionCountLeft,
  hasFunds,
  hasTransactionRange,
} from './selectors'

export const throwBadAccountError = () => {
  throw new APIError('Sorry, this account is unable to make payments.', '') // todo-ts: missing code
}

export function* handleInit(action) {
  try {
    yield put(paymentMakeReset())
    yield put(reset('paymentMakeVerify'))
    yield put(reset('paymentMakeNewPayee'))

    const { fromAccountId, toAccountId, isNewPayee } = action.payload

    if (fromAccountId) {
      yield put(uPaymentMakeFromUpdate(fromAccountId))
    }
    if (toAccountId) {
      yield put(paymentMakeToUpdate(toAccountId))
    }
    if (isNewPayee) {
      yield put(paymentMakeNewPayeeShow())
    }
    yield put(payeesFetchRequest())
  } catch (error) {
    const message = yield call(humanError, error)
    yield put(modalErrorShow(message))
  }
}

export function* handleFromUpdate(action) {
  try {
    const p = action.payload
    yield call(topOfPage)
    const account = yield select(getAccount, p.id)
    if (account.canMakePayment) {
      yield put(rPaymentMakeFromUpdate(p.id))
      let limitNonce = yield call(request, {
        id: requestNonce,
        url: 'v1/session/nonce',
      })

      const partyLimitUrl = 'v1/me/party/assignedLimitPackage?'
      const spentMoneyUrl = 'v1/financialLimitUtilization?entityType='
      const spentPartyMoneyEndPoint =
        '&limitType=PER%23DAILY&accessPointValue=APINTERNET&accessPointGroupType=SINGLE'

      const partyLimits = yield call(request, {
        id: requestGroupTransactionLimit,
        nonce: limitNonce,
        url: `${partyLimitUrl}accessPointValue=APINTERNET&accessPointGroupType=GROUP&locale=en`,
        partyId: account.customerId,
      })

      limitNonce = yield call(request, {
        id: requestNonce,
        url: 'v1/session/nonce',
      })

      const partyLimitUsed = yield call(request, {
        id: requestGroupTransactionLimitSpent,
        nonce: limitNonce,
        url: `${spentMoneyUrl}PARTY${spentPartyMoneyEndPoint}`,
        partyId: account.customerId,
      })
      const partyLimitUsedAmount = get(partyLimitUsed, 'makePayment.amount', '')
      const partyPaymentCount = get(partyLimitUsed, 'makePayment.count', '')

      const partyPaymentLimit = {}
      if (partyLimits) {
        partyPaymentLimit[account.customerId] = {
          txnRange: partyLimits.makePayment.txnRange,
          maxCount: parseInt(partyLimits.makePayment.maxCount) - partyPaymentCount,
          maxAmount: partyLimits.makePayment.txnLimit - partyLimitUsedAmount,
        }
      }
      yield put(
        userDetailsUpdate({
          partyPaymentLimit,
        })
      )
    } else {
      yield put(paymentMakeReset())
      yield call(throwBadAccountError)
    }
  } catch (error) {
    const message = yield call(humanError, error)
    yield put(modalErrorShow(message))
  }
}

export function* handleVerify(action) {
  try {
    const p = action.payload
    const pm = yield select(getPaymentMakeDetails)
    const dateOptions = yield call(dateOptionsProcess, p)
    const account = yield select(getFromAccount)
    const payeeDetails = pm.newPayeeDetails
    let requestParams: any = {} // todo-ts: was ''
    const partyId = account.customerId
    const { amount } = p
    const { reference } = p
    let payeeRefDetails
    if (pm.isNewPayee) {
      payeeRefDetails = {
        groupId: payeeDetails.groupId,
        payeeId: payeeDetails.payeeId,
        partyId,
      }
    }

    // Very occasionally, if the user is fast with the creation of a new payee and
    // then is quick to begin another payment, the success flag in state.paymentMake remains
    // set to true. This causes the TFA UI to never appear. The user receives the OTP, but the UI
    // simply shows a success, so the user cannot input the OTP. Because of this the transaction never
    // goes through and the new payee is not set up. So right here we ensure that the success property is set
    // to undefined. This took my 8 hours to figure out, so be happy it's here :D
    // The Jira tickets relating to this are NDBFE-8 and NDBFE-14
    yield put(paymentMakeResetSuccess())
    const canMakePayment = yield select(hasFunds, account, parseFloat(p.amount))
    const transactionLimit = yield select(hasTransactionRange, partyId, parseFloat(p.amount))
    const transactionLimitLeft = yield select(hasDailyLimitLeft, partyId, parseFloat(p.amount))
    const transactionCountLeft = yield select(hasDailyTransactionCountLeft, partyId)
    if (p.when === 'now' && !p.isRegular && !canMakePayment) {
      yield put(
        formError(paymentMakeVerify.FAILURE, {
          name: 'API',
          message: 'This account does not have the required funds to make this payment.',
        })
      )
    } else if (transactionLimit === 'amountTooSmall') {
      yield put(
        formError(paymentMakeVerify.FAILURE, {
          name: 'API',
          message: `This amount for this Payment Type is too small.
          Please contact the Bank Helpdesk on +44 (0) 1933 543543 for assistance.`,
        })
      )
    } else if (transactionLimit === 'amountTooBig') {
      yield put(
        formError(paymentMakeVerify.FAILURE, {
          name: 'API',
          message: `This amount exceeds the limit for this Payment Type.
          Please contact the Bank Helpdesk on +44 (0) 1933 543543 for assistance.`,
        })
      )
    } else if (!transactionLimitLeft) {
      yield put(
        formError(paymentMakeVerify.FAILURE, {
          name: 'API',
          message: `The transfer requested is above the daily limit and will not be processed.
          Please contact our Helpdesk on 01933 543543 for further information.`,
        })
      )
    } else if (!transactionCountLeft) {
      yield put(
        formError(paymentMakeVerify.FAILURE, {
          name: 'API',
          message: `The transfer requested is above the daily amount of transactions limit and will not be processed.
          Please contact our Helpdesk on 01933 543543 for further information.`,
        })
      )
    } else {
      if (!pm.isNewPayee) {
        let baseUrl = 'cz/v1/payments/payouts/external'
        if (p.isRegular || p.when !== 'now') {
          baseUrl = 'cz/v1/payments/instructions/payouts/external'
        }
        const requestDetails = {
          id: requestTransferOrPayment,
          payload: {
            type: 'payment',
            stage: 0,
            from: pm.from,
            to: pm.to,
            isNewPayee: pm.isNewPayee,
            later: p.when !== 'now',
            amount: p.amount,
            reference: p.reference,
            ...dateOptions.data,
          },
        }
        const nonce = yield call(request, {
          id: requestNonce,
          url: 'v1/session/nonce',
        })

        requestParams = yield call(request, {
          id: requestDetails.id,
          nonce,
          url: `${baseUrl}?locale=en`,
          partyId,
          payload: requestDetails.payload,
        })
      }
      const tempWarning = yield select(checkPaymentDateWarning, p.when, p)
      let warnings = ''
      if (tempWarning === '') {
        warnings =
          requestParams &&
          requestParams.data &&
          requestParams.data.status &&
          requestParams.data.status.message &&
          requestParams.data.status.message.detail
      } else if (requestParams?.data?.status?.message?.code === DIGXErrorCodes.DIGX_WW_017) {
        warnings = requestParams?.data?.status?.message?.detail || ''
      } else {
        warnings = tempWarning
      }
      yield put(
        paymentMakeDetailsUpdate({
          amount,
          reference,
          isRegular: p.isRegular,
          partyId,
          isNewPayee: pm.isNewPayee,
          later: p.when !== 'now',
          requestParams,
          payeeRefDetails,
          warnings,
          ...dateOptions.display,
        })
      )
    }
    yield put(stopSubmit('paymentMakeVerify'))
  } catch (error) {
    console.error(error)
    yield put(formError(paymentMakeVerify.FAILURE, error))
  }
}

export function* handleConfirm(action) {
  try {
    const pm = yield select(getPaymentMakeDetails)
    const selector = yield call(getFormValues, 'paymentMakeVerify')
    const formData = yield select(selector)
    const dateOptions = yield call(dateOptionsProcess, formData)
    const account = yield select(getFromAccount)
    const partyId = account.customerId
    let id = ''
    let baseUrl = 'cz/v1/payments/payouts/external'
    const payeeUrl = 'cz/v1/payments/payeeGroup'
    let later = false
    if (pm.isNewPayee) {
      yield put(
        paymentMakeDetailsUpdate({
          cancelDisabled: true,
        })
      )
      const nonce = yield call(request, {
        id: requestNonce,
        url: 'v1/session/nonce',
      })
      yield put(twoFactorAuthSetInitialRequest('payeeCreate'))
      const res = yield call(request, {
        id: requestPayeeNew,
        url: `${payeeUrl}/${pm.payeeRefDetails.groupId}/payees/external/${pm.payeeRefDetails.payeeId}?locale=en`,
        nonce,
        partyId,
        payload: {
          stage: 1,
          ...pm,
        },
      })
      const payeeRefDetails = {
        refNumber: res.ref,
        attemptsLeft: res.attemptsLeft,
        groupId: pm.payeeRefDetails.groupId,
        payeeId: pm.payeeRefDetails.payeeId,
        partyId,
      }
      yield put(twoFactorAuthSetRef(payeeRefDetails))
      yield put(
        twoFactorAuthSetSuccessAction({
          type: paymentMakeSubmit.REQUEST,
        })
      )
    }
    if (pm.isRegular || pm.later) {
      baseUrl = 'cz/v1/payments/instructions/payouts/external'
      later = true
    }
    const requestDetails = {
      id: requestTransferOrPayment,
      payload: {
        type: 'payment',
        stage: 0,
        from: pm.from,
        to: pm.to,
        isNewPayee: pm.isNewPayee,
        later,
        amount: pm.amount,
        reference: pm.reference,
        ...dateOptions.data,
      },
    }
    const { requestParams } = pm
    yield put(
      paymentMakeDetailsUpdate({
        cancelDisabled: true,
      })
    )
    const nonce2 = yield call(request, {
      id: requestNonce,
      url: 'v1/session/nonce',
    })
    if (pm.isRegular || pm.later) {
      id = requestParams.data.instructionId
    } else {
      id = requestParams.data.paymentId
    }
    yield call(request, {
      id: requestTransferOrPaymentGet,
      nonce: nonce2,
      partyId,
      url: `${baseUrl}/${id}?locale=en`,
    })
    if (pm.isRegular || !isEqual(pm.when, moment().format('DD/MM/YY'))) {
      baseUrl = 'cz/v1/payments/instructions/payouts/external'
      later = true
    }
    const nonce1 = yield call(request, {
      id: requestNonce,
      url: 'v1/session/nonce',
    })
    let payees
    let payee = yield select(getTheNewPayee)
    if (payee === undefined) {
      payees = yield select(getExistingPayees)
      payee = payees[pm.to]
    }
    const newPayeeSortCode = payee.sortCode ? payee.sortCode : yield select(getSortCode)
    const requestParamsPatch = yield call(request, {
      id: requestTransferOrPaymentPatch,
      nonce: nonce1,
      partyId,
      url: `${baseUrl}/${id}?sortCode=${newPayeeSortCode}&locale=en`,
      payload: requestDetails.payload,
    })
    yield put(
      paymentMakeDetailsUpdate({
        success: true,
        needsAuthoriser: requestParamsPatch.data.status.message.code === 'DIGX_APPROVAL_REQUIRED',
        timeOutError: requestParamsPatch.data.externalReferenceId === 'UNDER_PROCESSING',
        referenceNumber: requestParamsPatch.data.status.referenceNumber,
        warnings: pm.warnings,
      })
    )

    // #GA4-PROVISIONALLY-DISABLED
    // GA.event({
    //   category: 'Form',
    //   action: 'Submit',
    //   label: 'Make a Payment',
    // })

    yield put(accountsFetch())
  } catch (error) {
    console.error(error)
    yield put(formError(paymentMakeSubmit.FAILURE, error))
  }
}

export function* handleNewPayeeSubmit() {
  try {
    yield put(paymentMakeNewPayeeDetailsClear())
    const pm = yield select(getPaymentMakeDetails)
    const fromAccount = yield select(getFromAccount)
    const selector = yield call(getFormValues, 'paymentMakeNewPayee')
    const p = yield select(selector)

    let nonce = yield call(request, {
      id: requestNonce,
      url: 'v1/session/nonce',
    })

    const details = yield call(request, {
      id: requestPayeeNewInit,
      url: 'cz/v1/payments/payeeGroup?locale=en',
      partyId: fromAccount.customerId,
      nonce,
      payload: { ...p },
    })

    nonce = yield call(request, {
      id: requestNonce,
      url: 'v1/session/nonce',
    })

    const copApiResponse = yield select(getCopApiResponse)
    const details1 = yield call(request, {
      id: requestPayeeNewInit2,
      url: `cz/v1/payments/payeeGroup/${details}/payees/external?locale=en`,
      partyId: fromAccount.customerId,
      nonce,
      payload: { ...p, copApiResponse },
    })

    nonce = yield call(request, {
      id: requestNonce,
      url: 'v1/session/nonce',
    })

    yield call(request, {
      id: requestPayeeNewInit3,
      url: `cz/v1/payments/payeeGroup/${details}/payees/external/${details1.id}?locale=en`,
      partyId: fromAccount.customerId,
      nonce,
      payload: { stage: 0, ...pm.newPayeeDetails, ...p, customerId: fromAccount.customerId },
    })

    const userLimitUrl = 'v1/me/assignedLimitPackage?'
    const partyLimitUrl = 'v1/me/party/assignedLimitPackage?'
    const spentMoneyUrl = 'v1/financialLimitUtilization?entityType='
    const spentMoneyEndPoint =
      '&limitType=PER%23DAILY&accessPointValue=APINTERNET&accessPointGroupType=SINGLE'
    const spentPartyMoneyEndPoint =
      '&limitType=PER%23DAILY&accessPointValue=APINTERNET&accessPointGroupType=SINGLE'

    nonce = yield call(request, {
      id: requestNonce,
      url: 'v1/session/nonce',
    })

    const userLimits = yield call(request, {
      id: requestUserTransactionLimit,
      nonce,
      url: `${userLimitUrl}accessPointValue=APINTERNET&accessPointGroupType=SINGLE&locale=en`,
    })

    nonce = yield call(request, {
      id: requestNonce,
      url: 'v1/session/nonce',
    })

    const partyLimits = yield call(request, {
      id: requestGroupTransactionLimit,
      nonce,
      url: `${partyLimitUrl}accessPointValue=APINTERNET&accessPointGroupType=GROUP&locale=en`,
      partyId: fromAccount.customerId,
    })

    nonce = yield call(request, {
      id: requestNonce,
      url: 'v1/session/nonce',
    })

    const userLimitUsed = yield call(request, {
      id: requestUserTransactionLimitSpent,
      nonce,
      url: `${spentMoneyUrl}USER${spentMoneyEndPoint}`,
    })

    nonce = yield call(request, {
      id: requestNonce,
      url: 'v1/session/nonce',
    })

    const partyLimitUsed = yield call(request, {
      id: requestGroupTransactionLimitSpent,
      nonce,
      url: `${spentMoneyUrl}PARTY${spentPartyMoneyEndPoint}`,
      partyId: fromAccount.customerId,
    })
    const userLimitUsedAmount = get(userLimitUsed, 'makePayment.amount', '')
    const userPaymentCount = get(userLimitUsed, 'makePayment.count', '')
    const partyLimitUsedAmount = get(partyLimitUsed, 'makePayment.amount', '')
    const partyPaymentCount = get(partyLimitUsed, 'makePayment.count', '')
    let userPaymentLimit = {}
    if (userLimits) {
      userPaymentLimit = {
        txnRange: userLimits.makePayment.txnRange,
        maxCount: parseInt(userLimits.makePayment.maxCount) - userPaymentCount,
        maxAmount: userLimits.makePayment.txnLimit - userLimitUsedAmount,
      }
    }
    const partyPaymentLimit = {}
    if (partyLimits) {
      partyPaymentLimit[fromAccount.customerId] = {
        txnRange: partyLimits.makePayment.txnRange,
        maxCount: parseInt(partyLimits.makePayment.maxCount) - partyPaymentCount,
        maxAmount: partyLimits.makePayment.txnLimit - partyLimitUsedAmount,
      }
    }
    yield put(
      userDetailsUpdate({
        userPaymentLimit,
      })
    )
    yield put(
      userDetailsUpdate({
        partyPaymentLimit,
      })
    )

    const detailsGet = {
      ...p,
      title: p.name,
      groupId: details,
      payeeId: details1.id,
      sortCodeNice: formatSortCode(p.sortCode),
    }

    yield put(paymentMakeNewPayeeDetailsUpdate(detailsGet))
    yield put(paymentMakeToUpdate(true))
    yield put(modalHide())
  } catch (error) {
    yield put(formError(confirmPayee.FAILURE, error))
    yield put(copReset())
    yield put(stopSubmit('paymentMakeNewPayee'))
  }
}

export function* handlePaymentProcessAfterAuth(action) {
  const p = action.payload
  const { href } = window.location
  if (!href.includes('/payments/make')) {
    return
  }

  if (p.initialRequest === 'payeeCreate') {
    try {
      const pm = yield select(getPaymentMakeDetails)
      const selector = yield call(getFormValues, 'paymentMakeVerify')
      const formData = yield select(selector)
      const dateOptions = yield call(dateOptionsProcess, formData)
      const account = yield select(getFromAccount)
      const partyId = account.customerId
      const newPayeeId = pm.newPayeeDetails.payeeId
      let id = ''
      let baseUrl = 'cz/v1/payments/payouts/external'
      let later = false
      let requestParams: any = {}
      let warnings = ''
      if (pm.isRegular || pm.later) {
        baseUrl = 'cz/v1/payments/instructions/payouts/external'
        if (pm.later) {
          later = true
        }
      }
      const requestDetails = {
        id: requestTransferOrPayment,
        payload: {
          type: 'payment',
          stage: 0,
          from: pm.from,
          to: newPayeeId,
          isNewPayee: true,
          later,
          amount: pm.amount,
          reference: pm.reference,
          ...dateOptions.data,
        },
      }
      let nonce = yield call(request, {
        id: requestNonce,
        url: 'v1/session/nonce',
      })
      requestParams = yield call(request, {
        id: requestDetails.id,
        nonce,
        url: `${baseUrl}?locale=en`,
        partyId,
        payload: requestDetails.payload,
      })
      if (pm.warnings === '') {
        warnings =
          requestParams &&
          requestParams.data &&
          requestParams.data.status &&
          requestParams.data.status.message &&
          requestParams.data.status.message.detail
      } else {
        warnings = pm.warnings
      }
      nonce = yield call(request, {
        id: requestNonce,
        url: 'v1/session/nonce',
      })
      if (pm.isRegular || pm.later) {
        id = requestParams.data.instructionId
      } else {
        id = requestParams.data.paymentId
      }
      yield call(request, {
        id: requestTransferOrPaymentGet,
        nonce,
        partyId,
        url: `${baseUrl}/${id}?locale=en`,
      })
      if (pm.isRegular || pm.later) {
        baseUrl = 'cz/v1/payments/instructions/payouts/external'
        if (pm.later) {
          later = true
        }
      }

      nonce = yield call(request, {
        id: requestNonce,
        url: 'v1/session/nonce',
      })

      const payee = yield select(getTheNewPayee)
      const newPayeeSortCode = payee.sortCode ? payee.sortCode : yield select(getSortCode)

      const requestParamsPatch = yield call(request, {
        id: requestTransferOrPaymentPatch,
        nonce,
        partyId,
        url: `${baseUrl}/${id}?sortCode=${newPayeeSortCode}&locale=en`,
        payload: requestDetails.payload,
      })
      yield put(
        paymentMakeDetailsUpdate({
          success: true,
          needsAuthoriser: requestParamsPatch.data.status.message.code === 'DIGX_APPROVAL_REQUIRED',
          timeOutError: requestParamsPatch.data.externalReferenceId === 'UNDER_PROCESSING',
          referenceNumber: requestParamsPatch.data.status.referenceNumber,
          warnings,
        })
      )
      // #GA4-PROVISIONALLY-DISABLED
      // GA.event({
      //   category: 'Form',
      //   action: 'Submit',
      //   label: 'Make a Payment',
      // })
      yield put(accountsFetch())
    } catch (error) {
      console.error(error)
      yield put(formError(PINSubmit.FAILURE, error))
      yield put(formError(paymentMakeSubmit.FAILURE, error))
    }
  }
}

export function* watchPaymentMake() {
  yield takeLatest(PAYMENTMAKE_INIT, handleInit)
  yield takeLatest(U_PAYMENTMAKE_FROM_UPDATE, handleFromUpdate)
  yield takeLatest(paymentMakeVerify.REQUEST, handleVerify)
  yield takeLatest(paymentMakeSubmit.REQUEST, handleConfirm)
  yield takeLatest(PAYMENT_FLOW_CONFIRMATION_OF_PAYEE_SUBMIT, handleNewPayeeSubmit)
  yield takeLatest(TWO_FACTOR_AUTH_COMPLETE, handlePaymentProcessAfterAuth)
}
