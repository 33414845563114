import get from 'lodash/get'
import moment from 'moment'
import { connect } from 'react-redux'
import * as a from './actions'
import { panelHide } from '../../store/ui'

import SuperDatePicker from '../../components/SuperDatePicker'

const mapStateToProps = (state, ownProps) => {
  const picker = get(state, `superDatePicker.pickers[${ownProps.id}]`, undefined)
  const startDate = picker && picker.startDate
  const endDate = picker && picker.endDate
  const maxDate = picker && picker.maxDate
  const startDateText = picker && picker.startDateText
  const endDateText = picker && picker.endDateText
  const startDateNative = picker && moment(picker.startDate).format('YYYY-MM-DD')
  const endDateNative = picker && moment(picker.endDate).format('YYYY-MM-DD')
  const maxDateNative = picker && moment(picker.maxDate).format('YYYY-MM-DD')
  let yesterdayActive
  let last7daysActive

  if (startDate && endDate) {
    yesterdayActive =
      startDate.toString() === moment().subtract(1, 'days').startOf('day').toString() &&
      endDate.toString() === moment().subtract(1, 'days').startOf('day').toString()

    last7daysActive =
      startDate.toString() === moment().subtract(7, 'days').startOf('day').toString() &&
      endDate.toString() === moment().startOf('day').toString()
  }

  return {
    selecting: picker && picker.selecting,
    startDate,
    endDate,
    maxDate,
    startDateText,
    endDateText,
    startDateNative,
    endDateNative,
    maxDateNative,
    browser: state.browser,
    shortcuts: [
      {
        id: 'yesterday',
        text: 'Yesterday',
        active: yesterdayActive,
      },
      {
        id: 'last7days',
        text: 'Last 7 Days',
        active: last7daysActive,
      },
      {
        id: 'prevMonth',
        text: 'Previous Month',
      },
      {
        id: 'nextMonth',
        text: 'Next Month',
      },
    ],
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (date) => {
    dispatch(a.superDatePickerChange(ownProps.id, date))
  },

  onHandleStartDateChange: (date) => {
    dispatch(a.superStartDatePickerChange(ownProps.id, date))
  },
  onHandleEndDateChange: (date) => {
    dispatch(a.superEndDatePickerChange(ownProps.id, date))
  },
  onInputClick: (inputId) => {
    dispatch(a.superDatePickerInputClick(ownProps.id, inputId))
  },
  onInputChange: (inputId, value) => {
    dispatch(a.superDatePickerInputChange(ownProps.id, inputId, value))
  },
  onNativeInputChange: (inputId, value) => {
    dispatch(a.superDatePickerNativeInputChange(ownProps.id, inputId, value))
  },
  // Fake blur the input on enter to process field before submits
  onInputKeypress: (inputId, event) => {
    if (event.key === 'Enter') {
      dispatch(a.superDatePickerInputBlur(ownProps.id, inputId))
    }
  },
  onInputBlur: (inputId, value) => {
    dispatch(a.superDatePickerInputBlur(ownProps.id, inputId))
  },
  onShortcutClick: (shortcutId) => {
    dispatch(a.superDatePickerShortcutClick(ownProps.id, shortcutId))
  },
  onCancelClick: (e) => {
    e.preventDefault()
    dispatch(
      a.superDatePickerCancel(ownProps.id, ownProps.initialStartDate, ownProps.initialEndDate)
    )
    dispatch(panelHide())
  },
  onSubmit: (e) => {
    e.preventDefault()
    if (ownProps.onSubmit) ownProps.onSubmit(e)
    dispatch(a.superDatePickerUpdateLastSubmitted(ownProps.id))
    dispatch(panelHide())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(SuperDatePicker)
