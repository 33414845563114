import { connect } from 'react-redux'
import { change } from 'redux-form'
import PaymentMakeFromTo from '../../components/PaymentMakeFromTo'
import getPayingAccounts from '../../selectors/getPayingAccounts'
import { getExistingPayees, getFromAccount, getToAccount, getToId } from '../PaymentMake/selectors'
import { panelShow, panelHide } from '../../store/ui'
import * as a from '../PaymentMake/actions'
import convertToWithCurrency from './convertToWithCurrency'

const mapStateToProps = (state) => ({
  fromAccount: getFromAccount(state),
  toAccount: getToAccount(state),
  fromOptions: convertToWithCurrency(getPayingAccounts(state)),
  isChangingAccount: state.ui.panel === 'changeAccount',
  copStatus: state.cop.status,
  verificationReport: getExistingPayees(state)[getToId(state)]?.verificationReport,
})

const mapDispatchToProps = (dispatch) => ({
  onChangeFromAccountClick: (e) => {
    e.preventDefault()
    dispatch(panelShow('changeAccount'))
  },
  onChangeToAccountClick: (e) => {
    e.preventDefault()
    dispatch(change('paymentMakeVerify', 'reference', ''))
    dispatch(a.paymentMakeToUpdate(undefined))
  },
  onChangeFromAccountSelect: (e) => {
    dispatch(a.uPaymentMakeFromUpdate(e.value))
    dispatch(panelHide())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMakeFromTo)
