import React from 'react'
import s from './styles.css'
import AccountListItem from '../AccountListItem'
import ContentWarning from '../ContentWarning'

const loadingProps = {
  isLoadingEmpty: true,
  id: 'x',
  title: 'This is loading',
  accountNumber: 'XXXXXXXX',
  sortCode: 'XX-XX-XX',
  balance: '£1,000,000',
  maskedAccountNumber: 'xxxx',
  type: 'x',
}

type AccountListProps = {
  title?: string
  footerText?: string
  items?: any[]
  isLoadingEmpty?: boolean
}

const AccountList = ({ title, footerText, items, isLoadingEmpty }: AccountListProps) => (
  <>
    {isLoadingEmpty ? (
      <div className={s.root}>
        <header className={s.header}>
          <h2 className={s.empty}>Accounts are loading</h2>
        </header>
        <ul aria-hidden>
          <li className={s.row}>
            <AccountListItem {...loadingProps} />
          </li>
          <li className={s.row}>
            <AccountListItem {...loadingProps} />
          </li>
        </ul>
      </div>
    ) : (
      <nav className={s.root}>
        <header className={s.header}>
          <h2>{title}</h2>
        </header>
        <ul>
          {items.map((item) => (
            <li key={item.id} className={s.row}>
              <AccountListItem {...item} />
            </li>
          ))}
        </ul>
        {footerText && (
          <footer
            className={s.footer}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: footerText }}
          />
        )}
      </nav>
    )}
  </>
)

export default AccountList
