export default (items) => {
  // TODO
  if (items) {
    const newItems: { label: string; value: string | number }[] = []

    Object.keys(items).forEach((key) => {
      const item = items[key]
      if (item && item.currency === 'GBP') {
        newItems.push({
          label: item.title,
          value: item.id,
        })
      }
    })

    return newItems
  }
  return []
}
