import * as c from '../../constants'

export function superDatePickerChange(datePickerId: string, date: string) {
  return {
    type: c.SUPERDATEPICKER_CHANGE,
    payload: {
      datePickerId,
      date,
    },
  }
}

export function superDatePickerNativeInputChange(
  datePickerId: string,
  inputId: string,
  date: string
) {
  return {
    type: c.SUPERDATEPICKER_NATIVE_INPUT_CHANGE,
    payload: {
      datePickerId,
      inputId,
      date,
    },
  }
}

export function superDatePickerShortcutClick(datePickerId: string, shortcutId: string) {
  return {
    type: c.SUPERDATEPICKER_SHORTCUT_CLICK,
    payload: {
      datePickerId,
      shortcutId,
    },
  }
}

export function superDatePickerInit(datePickerId: string, startDate?: string, endDate?: string) {
  return {
    type: c.SUPERDATEPICKER_INIT,
    payload: {
      datePickerId,
      startDate,
      endDate,
    },
  }
}

export function superDatePickerCancel(datePickerId: string, startDate?: string, endDate?: string) {
  return {
    type: c.SUPERDATEPICKER_CANCEL,
    payload: {
      datePickerId,
    },
  }
}

export function superDatePickerInputClick(datePickerId: string, inputId: string) {
  return {
    type: c.SUPERDATEPICKER_INPUT_CLICK,
    payload: {
      datePickerId,
      inputId,
    },
  }
}

export function superStartDatePickerChange(datePickerId: string, date: string) {
  return {
    type: c.SUPERSTARTDATEPICKER_CHANGE,
    payload: {
      datePickerId,
      date,
    },
  }
}
export function superEndDatePickerChange(datePickerId: string, date: string) {
  return {
    type: c.SUPERENDDATEPICKER_CHANGE,
    payload: {
      datePickerId,
      date,
    },
  }
}

export function superDatePickerInputChange(datePickerId: string, inputId: string, value: string) {
  return {
    type: c.SUPERDATEPICKER_INPUT_CHANGE,
    payload: {
      datePickerId,
      inputId,
      value,
    },
  }
}

export function superDatePickerInputBlur(datePickerId: string, inputId: string) {
  return {
    type: c.SUPERDATEPICKER_INPUT_BLUR,
    payload: {
      datePickerId,
      inputId,
    },
  }
}

export function superDatePickerInputKeypress(
  datePickerId: string,
  inputId: string,
  event: string | boolean
) {
  return {
    type: c.SUPERDATEPICKER_INPUT_KEYPRESS,
    payload: {
      datePickerId,
      inputId,
      event,
    },
  }
}

export function superDatePickerUpdateLastSubmitted(datePickerId: string) {
  return {
    type: c.SUPERDATEPICKER_UPDATE_LAST_SUBMITTED,
    payload: {
      datePickerId,
    },
  }
}
