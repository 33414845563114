import React from 'react'
import s from './styles.css'

const ContentWarning = () => {
  return (
    <div className={s.root}>
      <div className={s.desktopContent}>
        We understand how stressful it can be if you are worried about money. We are always
        available if you think you may miss a mortgage/loan payment, exceed your overdraft limit or
        have any other financial concerns. Please contact your private banking team or Relationship
        Manager at the earliest opportunity so they can provide the support you need.
      </div>
      <div className={s.mobileContent}>
        We understand how stressful it can be if you are worried about money. We are always
        available to help with your financial concerns. Please contact us so we can provide the
        support you need.
      </div>
    </div>
  )
}

export default ContentWarning
