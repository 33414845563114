import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import s from './styles.css'
import SubmitCancel from '../../Forms/SubmitCancel'
import Shortcuts from '../Shortcuts'

interface MediumProps {
  onHandleStartDateChange: () => void
  onHandleEndDateChange: () => void
  startDateNative: string
  endDateNative: string
  startDate: moment.Moment
  maxDate: moment.Moment
  onNativeInputChange: (v1: string, v2: string) => void
  onShortcutClick: () => void
  onSubmit: () => void
  onCancelClick: () => void
  shortcuts?: any[]
  hideSubmit?: boolean
  className?: string
}

class Medium extends Component<MediumProps> {
  constructor(props) {
    super(props)
    this.onStartChange = this.onStartChange.bind(this)
    this.onEndChange = this.onEndChange.bind(this)
  }

  onStartChange(e) {
    this.props.onNativeInputChange('start', e.target.value)
  }

  onEndChange(e) {
    this.props.onNativeInputChange('end', e.target.value)
  }

  render() {
    const {
      onSubmit,
      onHandleStartDateChange,
      onHandleEndDateChange,
      startDateNative,
      startDate,
      maxDate,
      endDateNative,
      shortcuts,
      onShortcutClick,
      onCancelClick,
      hideSubmit,
      className,
    } = this.props

    const rootClass = className ? `${s.root} ${className}` : s.root

    return (
      <form className={rootClass} onSubmit={onSubmit}>
        <Shortcuts items={shortcuts} onShortcutClick={onShortcutClick} />
        <label htmlFor='dateStart'>Start date</label>
        <div className={s.medium}>
          <DatePicker
            selectsStart
            openToDate={startDate}
            maxDate={maxDate}
            value={startDateNative}
            onChange={onHandleStartDateChange}
            placeholderText='Select an start date'
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
          />
        </div>
        <label htmlFor='dateEnd'>End date</label>
        <div className={s.medium}>
          <DatePicker
            selectsEnd
            openToDate={maxDate}
            minDate={startDate}
            value={endDateNative}
            onChange={onHandleEndDateChange}
            placeholderText='Select an end date'
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
          />
        </div>
        {!hideSubmit && <SubmitCancel submitText='Apply Dates' onCancelClick={onCancelClick} />}
      </form>
    )
  }
}

export default Medium
