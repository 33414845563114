/* eslint-disable camelcase */
import { call, put, takeLatest } from 'redux-saga/effects'
import { COP_DTO_GENERIC_NAME, requestNonce, verifyPayeePost } from '../../constants'
import { formError } from '../../sagas/formErrors/actions'
import request from '../../utils/request'
import { confirmPayee, copFail, copLoading, copReset, copSuccess } from './actions'
import { prepareErrorState } from './helper'
import { ICopApiResponse, IErrorState } from './reducer'

export function* verifyPayee({
  payload,
}: {
  payload: {
    name: string
    sortCode: string
    sortCode_0: string
    sortCode_1: string
    sortCode_2: string
    accountNumber: string

    // TODO added fields:
    accountType: 'Personal' | 'Business'
    reference?: string
    payeeDescription?: string
  }
}) {
  const { name, sortCode, accountNumber, accountType, reference } = payload

  try {
    yield put(copLoading())

    const nonce = yield call(request, {
      id: requestNonce,
      url: 'v1/session/nonce',
    })

    const payeeRequest = yield call(request, {
      id: verifyPayeePost,
      nonce,
      url: `v1/payments/verifyPayeeName?locale=en`,
      payload: {
        paymentType: 'DOMESTIC',
        network: 'FASTER',

        accountId: `${sortCode}${accountNumber}`,
        accountName: name,
        accountType,
        bic: sortCode,

        dictionaryArray: [
          {
            nameValuePairDTOArray: [
              {
                name: COP_DTO_GENERIC_NAME,
                genericName: COP_DTO_GENERIC_NAME,
                value: reference,
                datatype: 'String',
              },
            ],
          },
        ],
      },
    })
    const apiResponse: ICopApiResponse = {
      matched: payeeRequest.data.matched,
      enabled: payeeRequest.data.enabled,
      selectionStatus: true,
      reasonCode: payeeRequest.data.reasonCode,
      ...(payeeRequest.data.payeeAccountName && {
        payeeAccountName: payeeRequest.data.payeeAccountName,
      }),
    }

    if (payeeRequest.data.matched === 'Y') {
      yield put(copSuccess(apiResponse))

      return
    }

    const response = {
      status: payeeRequest.status,
      matched: payeeRequest.data.matched,
      payeeAccountName: payeeRequest.data.payeeAccountName,
      reasonCode: payeeRequest.data.reasonCode,
    }

    const errorState = prepareErrorState(
      response.reasonCode,
      payeeRequest.data.payeeAccountName,
      accountType
    )

    const stateObject: IErrorState = {
      errorCode: response.reasonCode,
      ...errorState,
    }

    yield put(copFail(apiResponse, stateObject))
  } catch (error) {
    yield put(copReset())
    yield put(formError(confirmPayee.FAILURE, error))
  }
}
export function* watchConfirmationOfPayee() {
  yield takeLatest(confirmPayee.REQUEST, verifyPayee)
}
