import { connect } from 'react-redux'
import AuthorisationsView from '../../components/AuthorisationsView'
import { getVisibleAuthorisations } from './selectors'

const mapStateToProps = (state, ownProps) => {
  let resultsState
  const items = getVisibleAuthorisations(state)
  const displayId = Object.keys(state.entities.items ?? {}).map(
    (key) => state.entities.items[key].displayId
  )
  const filteredItems = Array.isArray(items)
    ? items.filter((item) => displayId.includes(item.customerId))
    : undefined
  if (state.authorisations.list.isFetching) {
    resultsState = 'loading'
  } else if (items.length === 0) {
    resultsState = 'completelyEmpty'
  }
  return {
    filteredItems,
    resultsState,
  }
}

export default connect(mapStateToProps)(AuthorisationsView)
