import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import ActionHeader from '../ActionHeader'
import InnerContainer from '../InnerContainer'
import AuthorisationsCard from '../../containers/Authorisations/card'
import ResultsInfo from '../ResultsInfo'
import { ResultsInfoDateState } from '../../models/types'

type AuthorisationsViewProps = {
  resultsState?: ResultsInfoDateState // todo - was string, but changed due to ResultsInfo
  filteredItems?: any[] // todo - array of object
}

export const AuthorisationsView = ({ filteredItems, resultsState }: AuthorisationsViewProps) => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/settings',
          text: 'Details & settings',
        },
        {
          to: '/settings/authorisations',
          text: 'Authorisations',
        },
      ]}
    />

    <InnerContainer>
      <ActionHeader title='Authorisations' />
      {resultsState ? (
        <ResultsInfo state={{ state: resultsState }} />
      ) : (
        <div>
          {filteredItems.map((itemProps) => (
            <AuthorisationsCard key={itemProps.reference} {...itemProps} />
          ))}
        </div>
      )}
    </InnerContainer>
  </div>
)

export default AuthorisationsView
